import clsx from 'clsx';
import React from 'react';
import { useLocation } from 'react-router';
import { NavigationItemProps } from '../types';

export function NavigationItem({ to, label, active }: NavigationItemProps) {
  return (
    <li>
      <a
        title={label}
        className={clsx(
          active
            ? 'text-green-dark border-b-2 border-white hover:text-green-darker'
            : 'text-brown-light hover:text-gray-50',
          'transition duration-300 ease-in-out cursor-pointer'
        )}
        href={to}
      >
        {label}
      </a>
    </li>
  );
}

export default function Navigation() {
  const activePath = useLocation().pathname;

  return (
    <div className="bg-brown-main py-4 h-32 hidden sm:block">
      <div className="text-center items-center pt-1 oswald text-uppercase font-light">
        <h3>
          <a
            title="Susan Saladino"
            className="leading-tight text-4xl text-brown-dark hover:text-white transition duration-500 ease-in-out"
            href="/"
          >
            Susan Saladino
          </a>
        </h3>
        <ul className="py-2 flex items-center justify-center space-x-6 text-lg">
          <NavigationItem
            label="Home"
            to="/"
            active={!activePath || activePath === '/'}
          />

          <NavigationItem
            label="About"
            to="/about"
            active={!activePath || activePath.includes('/about')}
          />

          <NavigationItem
            label="Works"
            to="/portfolio"
            active={!activePath || activePath.includes('/portfolio')}
          />

          <NavigationItem
            label="Portraits"
            to="/portraits"
            active={!activePath || activePath.includes('/portraits')}
          />

          <NavigationItem
            label="Contact"
            to="/contact"
            active={!activePath || activePath === '/contact'}
          />

          <NavigationItem
            label="Did you know ?"
            to="/did-you-know"
            active={!activePath || activePath === '/did-you-know'}
          />
        </ul>
      </div>
    </div>
  );
}
