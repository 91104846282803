import React from 'react';
import { useLocation } from 'react-router';
import shallow from 'zustand/shallow';
import { useStore } from '../stores/cart';

export default function CartIcon() {
  const cart = useStore((state) => state.cart, shallow);

  const { pathname } = useLocation();

  if (cart && cart.length && !pathname.includes('cart')) {
    return (
      <div className="fixed bottom-0 right-0 mr-6 mb-6">
        <div className="rounded-full bg-white text-green-dark hover:bg-gray-100 transition duration-300 flex shadow-sm">
          <a
            href="/cart"
            className="h-full w-full p-3 flex flex-col items-center justify-center"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
              />
            </svg>
            <p className="text-sm font-medium">Checkout</p>
          </a>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
