export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export type Notification = {
  type: NotificationType;
  title: string;
  message: string;
};

export type NotificationStore = {
  notifications: Notification[];

  addNotification(type: NotificationType, title: string, message: string): void;

  popNotifications(): void;
};
