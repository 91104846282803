import noScroll from 'no-scroll';
import React, { useEffect } from 'react';
import { WithChildren } from '../types';
import CartIcon from './CartIcon';
import Footer from './Footer';
import MobileMenu from './MobileMenu';
import Navigation from './Navigation';
import Toast from './Toast';
import useToggle from './utils/useToggle';

export default function Layout({ children }: WithChildren) {
  const [open, { toggle }] = useToggle(false);

  useEffect(() => {
    if (open) {
      noScroll.on();
    } else {
      noScroll.off();
    }

    return () => noScroll.off();
  }, [open]);

  // TODO: look into 'react-helmet'
  return (
    <div className="flex flex-col min-h-screen">
      <Navigation />
      <MobileMenu open={open} toggle={toggle} />
      <main className="main-height flex-1 max-w-sm sm:max-w-lg md:max-w-4xl lg:max-w-5xl xl:max-w-6xl mx-auto pb-24 px-3 md:px-0 overflow-x-hidden sm:py-12 py-8">
        <Toast />
        <React.Suspense fallback={<></>}>{children}</React.Suspense>
        <CartIcon />
      </main>
      <Footer />
    </div>
  );
}
