import create from 'zustand';
import { persist } from 'zustand/middleware';
import { CartItem } from '../types';
import { NotificationStore } from './notification';

export type OrderDetails = {
  orderedCart: CartItem[];
  email: string;
  id: number;
  firstName: string;
  lastName: string;
  addressOne: string;
  suite: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  amount: string;
  // tax: string;
};

type Store = {
  cart: CartItem[];
  orderDetails?: OrderDetails;

  setOrderDetails(orderDetails?: OrderDetails): void;

  acknowledgedCartDisclaimer: boolean;
  setCartDisclaimer(newValue: boolean): void;

  updateCart(newItem: CartItem): void;
  updateCartItemQuantity(index: number, newQuantity: number): void;
  deleteItemFromCart(index: number): void;
  clearCart(): void;
} & NotificationStore;

export const useStore = create<Store>(
  persist(
    (set, get) => ({
      cart: [],
      orderDetails: undefined,

      setOrderDetails: function (orderDetails) {
        set((state) => ({
          ...state,
          orderDetails,
        }));
      },

      acknowledgedCartDisclaimer: false,

      setCartDisclaimer: function (newValue) {
        set((state) => ({
          ...state,
          acknowledgedCartDisclaimer: newValue,
        }));
      },

      updateCart: function (newItem) {
        let newCart = get().cart;

        const { title, size, material, quantity } = newItem;

        let existing = newCart.findIndex(
          (el) =>
            el.title === title && el.size === size && el.material === material
        );

        if (existing !== -1) {
          let updatedEntry = {
            ...newCart[existing],
            quantity: newCart[existing].quantity + quantity,
          };

          newCart.splice(existing, 1, updatedEntry);

          set((state) => ({
            ...state,
            cart: newCart,
          }));
        } else {
          set((state) => ({
            ...state,
            cart: [...state.cart, newItem],
          }));
        }
      },

      updateCartItemQuantity: function (index, newQuantity) {
        let newCart = get().cart;
        let newItem = { ...newCart[index], quantity: newQuantity };
        newCart[index] = newItem;

        set((state) => ({
          ...state,
          cart: [...newCart],
        }));
      },

      deleteItemFromCart: function (index) {
        set((state) => ({
          ...state,
          cart: [...state.cart.filter((_, i) => i !== index)],
        }));
      },

      clearCart: function () {
        set((state) => ({
          ...state,
          cart: [],
        }));
      },

      notifications: [],
      addNotification: function (type, title, message) {
        set((state) => ({
          ...state,
          notifications: [...state.notifications, { type, title, message }],
        }));
      },

      popNotifications: function () {
        let newNotifs = [...get().notifications];
        newNotifs.shift();

        set((state) => ({
          ...state,
          notifications: newNotifs,
        }));
      },
    }),

    {
      name: 'susansaladino-cart-items',
      getStorage: () => sessionStorage,
    }
  )
);
