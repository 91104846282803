import clsx from 'clsx';
import React from 'react';
import { useLocation } from 'react-router';
import { MobileMenuProps, NavigationItemProps } from '../types';

function NavigationItem({ to, label, active }: NavigationItemProps) {
  return (
    <li>
      <a
        title={label}
        className={clsx(
          active
            ? 'text-green-dark border-b-2 border-green-dark hover:text-green-darker'
            : 'text-brown-dark hover:text-gray-50',
          'transition duration-300 ease-in-out cursor-pointer'
        )}
        href={to}
      >
        {label}
      </a>
    </li>
  );
}

export default function MobileMenu({ open, toggle }: MobileMenuProps) {
  const activePath = useLocation().pathname;

  return (
    <div className="sm:hidden">
      <div className="flex justify-between items-center bg-brown-main p-3 h-24">
        <h3>
          <a
            title="Susan Saladino"
            className="leading-tight text-3xl text-brown-dark hover:text-white transition duration-500 ease-in-out"
            href="/"
          >
            Susan Saladino
          </a>
        </h3>
        <div
          onClick={toggle}
          id="nav-icon3"
          className={clsx('h-5 w-5 text-black', open && 'open')}
        >
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>

      <aside
        className={clsx(
          open && 'open',
          'bg-brown-mainish px-4 fixed flex flex-col w-full h-full border-r-2 shadow-lg overflow-hidden z-10'
        )}
      >
        <ul className="pt-4 flex-1 font-medium text-xl tracking-wide flex flex-col space-y-6">
          <NavigationItem
            label="Home"
            to="/"
            active={!activePath || activePath === '/'}
          />

          <NavigationItem
            label="About"
            to="/about"
            active={!activePath || activePath.includes('/about')}
          />

          <NavigationItem
            label="Works"
            to="/portfolio"
            active={!activePath || activePath.includes('/portfolio')}
          />

          <NavigationItem
            label="Portraits"
            to="/portraits"
            active={!activePath || activePath.includes('/portraits')}
          />

          <NavigationItem
            label="Contact"
            to="/contact"
            active={!activePath || activePath === '/contact'}
          />

          <NavigationItem
            label="Did you know ?"
            to="/did-you-know"
            active={!activePath || activePath === '/did-you-know'}
          />
        </ul>
      </aside>
    </div>
  );
}
