import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { init } from 'emailjs-com';
import App from './App';

require('dotenv').config();

init(process.env.REACT_APP_EMAILJS_USER_ID!);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
