import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';

const About = React.lazy(() => import('./pages/About'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Landing = React.lazy(() => import('./pages/Landing'));
const Portraits = React.lazy(() => import('./pages/Portraits'));
const Resume = React.lazy(() => import('./pages/Resume'));
const CategoryPage = React.lazy(() => import('./pages/CategoryPage'));
const GalleryPage = React.lazy(() => import('./pages/GalleryPage'));
const ImageView = React.lazy(() => import('./pages/ImageView'));
const Cart = React.lazy(() => import('./pages/Cart'));
const Checkout = React.lazy(() => import('./pages/Checkout'));
const PurchaseThankyou = React.lazy(() => import('./pages/PurchaseThankyou'));
const Links = React.lazy(() => import('./pages/Links'));
const Lost = React.lazy(() => import('./pages/Lost'));
const Imagine = React.lazy(() => import('./pages/Imagine'));
const Statement = React.lazy(() => import('./pages/statements'));

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/links" element={<Links />} />
          <Route path="/about" element={<About />} />
          <Route path="/about/resume" element={<Resume />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<CategoryPage />} />
          <Route path="/portfolio/:gallery" element={<GalleryPage />} />
          <Route path="/portfolio/:gallery/:id" element={<ImageView />} />
          <Route path="/portfolio/statements/:title" element={<Statement />} />
          <Route path="/portraits/:gallery/:id" element={<ImageView />} />
          <Route path="/portraits" element={<Portraits />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/cart/checkout" element={<Checkout />} />
          <Route path="/order/thankyou" element={<PurchaseThankyou />} />
          <Route path="/did-you-know" element={<Imagine />} />

          <Route path="*" element={<Lost />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
